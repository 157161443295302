<template>
  <div style="height: 100%;">
    <Header message="/contact"></Header>
    <div class="box">
      <div class="game_studio">
        <div class="studio_left  wow animate__animated animate__zoomInUp">
          <div class="studio_name"><strong>{{ studio_name }}</strong></div>
          <!-- <h4 class="studio_declaration">Email:</h4>
          <p class="brief_introduction">support@joyboxgame.com</p> -->
        </div>
        <div class="studio_right  wow animate__animated animate__fadeInDown">
          <!-- <img src="@/assets/images/abi-game-studio.png" alt=""> -->
          <iframe
              id='aihelp-support-frame'
              src='https://gamewavelimited.aihelp.net/hc/app/19-Dragon_Gems/en/contact-us'
              width='100%'
              height='100%'
              frameborder='0'>
          </iframe>
          <h4 class="studio_declaration" style="color: #fff; font-size: 24px; margin: 50px 0 30px;">Address: <span class="brief_introduction" style="color: #c3c3c3; font-size: 16px;"> {{ brief_introduction }}</span></h4>
          
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'ContactPage',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      studio_name: 'CONTACT US',
      brief_introduction: "4660 S. Eastern Avenue Suite 204C，Las Vegas, Nevada，89119，USA",
      data_display1:'110+',
      data_display2:'150+',
      data_display3:'9M+',
      data_display4:'2B',
      images: [ 2018, 2021, 2022 ],
      ruleForm: { },
            rules: {
                name: [
                    { required: true, message: 'Name cannot be empty!', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: 'Please enter your email address', trigger: 'blur' },
                    { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
                ],
                content: [{ required: true, message: 'The message content cannot be empty!', trigger: 'blur' },],
            }
    }
  },
  computed: {
    currentImage() {
      if (this.activeStep==1) {
        return 2018;
      }else if(this.activeStep==2){
        return 2021;
      }else{
        return 2022
      }
    },
    steps_text(){
      return this.steps[this.images.indexOf(this.currentImage)].step_text;
    }
  },
  methods:{
    handleStepChange(newStep) {
      this.activeStep = newStep+1; // Adjust index because change event passes the new step index
    },
    submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                  window.location.href = `mailto:support@joyboxgame.com?subject=dragonfirenet&body=${this.ruleForm}`;
                } else {
                  console.log('error submit!!');
                  return false;
                }
            });
        },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 900px) {
  .game_studio{
    width: 95%;
    padding-bottom: 100px;
    .studio_right{
      padding-top: 50px;
      width: 95%;
      height: 1100px !important;
      // img{
      //   width: 2.8073rem;
      //   height: 2.3281rem;
      // }
    }
  }
}
@import url(@/fontCss/fontCss.css);
.box{
  .game_studio{
    width: 80%;
    padding-bottom: 100px;
    .studio_right{
      padding-top: 50px;
      width: 95%;
      height: 1500px !important;
      // img{
      //   width: 2.8073rem;
      //   height: 2.3281rem;
      // }
    }
  }
}
.game_studio {
  width: 5.9375rem;
  margin-top: .2604rem !important;
  padding: .5208rem 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .studio_right{
    padding-top: .2865rem;
    width: 80%;
    height: 1500px !important;
    // img{
    //   width: 2.8073rem;
    //   height: 2.3281rem;
    // }
  }
}
.studio_left{
  padding-top: .2604rem;
}
.studio_left>.studio_name{
  font-size: .1875rem;
  line-height: .1354rem;
  color: #FB9C2C;
}
.studio_left>.studio_declaration{
  font-size: .0833rem;
  line-height: .3229rem;
  color: #fff;
  padding: 0 0 .0521rem;
}
.studio_left>.brief_introduction{
  width: 2.8021rem;
  font-size: .0781rem;
  font-family: '楷书';
  line-height: .1563rem;
  color: #B3B3B3;
}
.studio_button{
  width: 1.3021rem;
  height: .3125rem;
  background-image: url(@/assets/images/button.png);
  background-size: cover;
  margin-top: .1563rem;
  text-align: center;
  line-height: .3125rem;
  font-size: .0938rem;
  color: #8F1209;
}
.studio_left>.studio_right{
  width: 2.8073rem;
  height: 2.2031rem;
}

.data_display{
  width: 5.8854rem;
  margin: .7813rem auto ;
  display: flex;
  justify-content: space-evenly;
}
.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .2083rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: .2708rem;
    line-height: .3802rem;
    color: #fff;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: .1667rem;
    line-height: .2188rem;
  }
}
.border_left{
  border-left: .026rem solid #FB9C2C;
  text-align: left;
}
// 步骤条
.image-container {
  width: 5.9375rem;
  height: 3.6458rem;
  text-align: center;
}
 
.image-container>img {
  max-width: 5.9115rem;
  max-height: 3.3281rem;
}
.steps{
  width: 5.9375rem;
  margin: 0 auto;
  position: relative;
}
.steps::before {
    position: absolute;
    content: "";
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    top: 0;
    left: 0;
}
.steps_body{
  width: 4.1667rem !important;
  left: .9375rem;
  position: absolute;
  bottom: .2083rem;
}
/* 被选中的步进条颜色 */
.stepsStyle{
   width: 100%;
   ::v-deep{

     .el-step__title.is-process,.el-step__title.is-wait{
       color: #fff !important;
     }
     .el-step__head.is-process,.el-step__heads.is-wait{
       .el-step__icon.is-text{//这里设置的是下一步和等待状态里的文字颜色
         color: rgb(197, 201, 198) !important;
         border-color: rgb(197, 201, 198) !important;
       }
     }
     .el-step__head.is-finish{
       color: rgb(28, 204, 72) !important;
       .el-step__icon.is-text{//这里设置的是已完成状态里的文字颜色
         color: rgb(28, 204, 72) !important;
         border-color: rgb(28, 204, 72) !important;
       }
     }
     .el-step__title.is-finish{
       color: rgb(28, 204, 72) !important;
     }
     .el-step__line-inner{//这里设置的是完成后过渡线条颜色
       border-color: rgb(28, 204, 72) !important;
     }
     .el-step__head.is-success,.el-step__title.is-success{//这里设置的是完成后的颜色
       color: rgb(28, 204, 72) !important;
     }
   }
 }
 .text_box{
  position: absolute;
  z-index: 99;
  left: 3.8802rem;
  bottom: 2.0833rem;
  h2{
    font-size: .1667rem;
    line-height: .1667rem;
    color: #FB9C2C;
    margin-bottom: .2604rem;
  }
  p{
    font-size: .1042rem;
    color: #fff;
  }
 }
</style>