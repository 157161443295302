<template>
    <div>
        <Header :message="'/pric.html'"></Header>
        <div class="pric">
            <h1 class="title">Policy</h1>
            <p>Your privacy is important to Lovely Monster and we take our responsibility of caring for it seriously. This policy describes how JoyBox Studio Limited (“JBS”) processes the personal information we collect about you when you use our products and services with JoyBox Studio Limited (collectively, "Services" ).</p>
            <p>
                <strong>Now the Lovely Monster app is owned and operated by</strong>
                JOYBOX STUDIO LIMITED(4660 S. Eastern Avenue Suite 204C，Las Vegas, Nevada，89119，USA) .
            </p>
            <p class="tit">1. Information We Collect</p>
            <p>The information we collect depends on what Services you use and how you use them.</p>
            <p>A. Information You Give Us Directly</p>
            <p>We collect information that you give us directly, such as when you create an Account, set up security questions, make purchases, or contact  Help.</p>
            <p>
                We collect information that you provide us, such as:
                <span class="p2">Your Account information, including your email address, username, real name, country, date of birth, telephone number and password;</span>
                <span class="p2">Security questions and answers;</span>
                <span class="p2">Information you provide in response to surveys about our Services;</span>
                <span class="p2">Information you provide when seeking help from us, such as your name, telephone number, and records (including call recordings) of the issues you experience; and</span>
                <span class="p2">Billing information, such as your name, payment card number, payment account details.</span>
            </p>
            <p></p>
            <p>B. Information You Provide When Playing Online</p>
            <p>When you play online, we may collect information about your gaming profile, your gameplay.</p>
            <p>When you play our online games, your in-game profile information, gameplay, and statistics are collected and some may be visible in-game. In some of our titles, we may record your gameplay and replay it to other players in-game and/or make it visible to other players outside the game in other JBS Services.</p>
            <p></p>
            <p>C. Other Information We Collect When You Use Our Services</p>
            <p>We may collect other information when you use our Services, such as information about your device, hardware and software information, IP address, or browser information.</p>
            <p>
                We may collect other information automatically when you use our Services, such as:
                <span class="p2">IP address;</span>
                <span class="p2">Information about your device, hardware, and software, such as your hardware identifiers, mobile device identifiers (like Apple Identifier for Advertising [IJBSA], or Android Advertising ID [AAID]), platform type, settings and components;</span>
                <span class="p2">Approximate geolocation data (derived from IP or device settings);</span>
                <span class="p2">Browser information, including your browser type and the language preference;</span>
                <span class="p2">Referring and exit pages, including pages viewed and other interactions with web content;</span>
                <span class="p2">Details about what Services you purchase or obtain, and your use of them;</span>
                <span class="p2">Device event information, including crash reports, request and referral URLs, and system activity details (e.g., whether you encountered an error playing our games or lost Internet access); and</span>
                <span class="p2">Other information (such as your likeness) that you may provide as part of your participation in live events.</span>
            </p>
            <p>We also may collect and store information locally on your device, using mechanisms like cookies, browser web storage (including HTML 5), and application data caches.</p>
            <p></p>
            <p></p>
            <p class="tit">2. How We Collect Your Information</p>
            <p>We use various technologies to deliver and measure the effectiveness of our Services, to serve advertisements, and to enhance your player experience. These technologies include cookies and similar technologies, as well as analytics, advertising, anti-cheat, and anti-fraud technologies.</p>
            <p>We and third parties use technologies described below to collect information to deliver our Services.</p>
            <p>A. Cookies and Similar Technologies</p>
            <p>Cookies are small text files stored on your Internet browser. We and our third-party partners use cookies and similar tracking technologies to help us understand things like what web pages, features, or ads you view and what games you play. This information helps us keep track of your shopping cart, measure the effectiveness of our advertising, make sure you don't see the same ad repeatedly, and otherwise enhance your experience.</p>
            <p>We may use tracking pixels or clear GIFs to collect information about your use of our Services and your response to our marketing emails. We use Internet log files (which contain technical data such as your IP address) to monitor traffic on our Services, troubleshoot technical problems, detect and prevent fraud, and enforce our User Agreement.</p>
            <p>We also use Analytics, Advertising, Anti-Cheat, and Anti-Fraud technologies in our Services. These technologies may utilize the same cookies or similar mechanisms as further described below.</p>
            <p></p>
            <p>B. Analytics Technologies</p>
            <p>We use internal and third-party analytics technologies to collect information about how you use our Services, your computer or device and the health of our Services.</p>
            <p>For instance, we may collect and store data from your computer or device when you use our Services. This information includes technical and related information about your computer, device, and operating and/or network systems (such as IP Address and device identifiers); information about your engagement with certain features and services; gameplay and usage statistics; as well as system interactions and peripheral hardware. We may also collect and store data from your device to create probabilistic identifiers for the purposes of analyzing the services accessed by our players across devices. If you play a game offline without connecting to the Internet, this data will be stored on your device and transmitted to us when your device connects to the Internet. If you participate in online Services, we may also collect, use, store, transmit and publicly display your personal and/or statistical data regarding your game play (including scores, rankings and accomplishments), or identify content that is created and shared by you with other players.</p>
            <p></p>
            <p>C. Ad Serving Technologies</p>
            <p>Some of our Services employ ad serving technologies that use cookies, clear GIFs, web beacons, tracking pixels, SDK, API, and other similar technologies to deliver offers and advertising to you within JBS Services as well as on third-party platforms and sites, and to measure the performance of our advertising campaigns. Some of these technologies can sync or connect behavior across different websites, mobile apps and devices to tailor offers and advertising to your interests. For instance, you may be presented with an offer for a game we think you might enjoy. Or, if you express interest in a game, you may receive an advertisement later for that game or similar Services on JBS.</p>
            <p>
                These technologies collect and use information so we can serve appropriate offers and advertising to you, to measure effectiveness and distribution of these offers and advertising, and to understand how users interact with our Services.This information may include:
                <span class="p2">Advertisements served and the location of the ad;</span>
                <span class="p2">Length of time an advertisement was visible;</span>
                <span class="p2">Domain type, IP address, size of the advertisement;</span>
                <span class="p2">Your interaction, if any, with the ad; and</span>
                <span class="p2">Advertisement response.</span>
            </p>
            <p></p>
            <p>D. Anti-Cheat and Fraud Prevention Technologies</p>
            <p>We strive to provide a safe and fair gaming environment for all players. When you use our Services, we or third parties may use cookies and similar technologies and collect data about your machine or device, to create a machine "fingerprint" or "hash" of your machine components, for fraud prevention, security and authentication purposes. We may also monitor publicly-available information, third-party sites, and/or use anti-cheat technology within our Services, including automated anti-fraud and abuse algorithms. If you believe your access to our games and/or Services was blocked by this technology in error, please contact us at support@joyboxgame.com.</p>
            <p></p>
            <p></p>
            <p>3. How We Use Your Information</p>
            <p>We use your information to operate our Services, to improve your game experience, to provide you customer support, to serve and measure the effectiveness of advertising, and to personalize our communications with you.</p>
            <p>We use your personal information, both individually and combined together, in the following ways.</p>
            <p>
                To operate our business and to enhance and personalize your game experience, including to:
                <span class="p2">Operate, analyze, improve, and develop our games and Services;</span>
                <span class="p2">Undertake research and analysis to develop and demonstrate our technology, and to better understand our players;</span>
                <span class="p2">Populate online leaderboards and enable online matchmaking;</span>
                <span class="p2">Provide replays to you and other players via spectator modes;</span>
                <span class="p2">Facilitate sharing on social networks;</span>
                <span class="p2">Manage and serve advertising in-game and on third-party platforms;</span>
                <span class="p2">Measure the effectiveness and distribution of advertising, including understanding who engages with our Services and how they interact with our Services;</span>
                <span class="p2">Measure the health of our Services;</span>
                <span class="p2">Detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, and prosecute those responsible for that activity;</span>
                <span class="p2">Set up and maintain accounts for which you register;</span>
                <span class="p2">Provide game experiences that you may like, including personalizing your game experience;</span>
                <span class="p2">Set up and facilitate loyalty programs;</span>
                <span class="p2">Facilitate your gameplay on multiple devices and/or platforms, as applicable;</span>
                <span class="p2">Identify, fix, and troubleshoot bugs or functionality errors;ide software updates;</span>
                <span class="p2">Authenticate and activate your games and save game-play data, including progress;</span>
                <span class="p2">Perform audits, transactions, and other compliance activities;</span>
                <span class="p2">Adhere to your preferences and to deliver content;</span>
                <span class="p2">Help keep our Services safe and fair, resolve disputes, investigate and help curb fraud and illegal behavior, comply with the law, and to enforce our agreements and policies; and</span>
                <span class="p2">Other purposes you consent to, are notified of, or are otherwise disclosed to you when you provide personal information.</span>
                <span class="p2"></span>
            </p>
            <p>
                To provide you support, including to:
                <span class="p2">Help identify and troubleshoot problems with your account or Services;</span>
                <span class="p2">Facilitate surveys or questionnaires;</span>
                <span class="p2">Communicate with you and respond to your specific requests or questions; and</span>
                <span class="p2">Manage and send you confirmations and important information about your account, Services, purchases, and warranties.</span>
            </p>
            <p>
                To personalize our communications with you, including to:
                <span class="p2">Provide you with relevant JBS content, recommendations, information or offers; and</span>
                <span class="p2">Provide you with Service updates.</span>
            </p>
            <p><strong>Retention.</strong>We retain the information we collect for as long as necessary to provide our Services, and we may retain that information beyond that period if necessary for legal, operational, or other legitimate reasons.</p>
            <p>Where possible, we may also de-identify, anonymize, or aggregate the information we collect, or collect it in a way that does not directly identify you. We may use and share such information as necessary for our business purposes and as permitted by applicable law.</p>
            <p></p>
            <p></p>
            <p>4. Your Choices and Controls</p>
            <p>We provide you with choice and control about our use of your information, including your ability to exercise your data privacy rights.</p>
            <p>We give you meaningful choices when it comes to our collection and use of your information. For example, if you no longer want to receive marketing emails from JBS, you may click the unsubscribe link included in the footer of any marketing email we send.</p>
            <p><strong>Verification of account ownership. </strong>To protect your account security and personal information, you may be asked to verify your account ownership prior to us processing your request to exercise your data privacy rights. Verification methods include being logged into your Account, confirming ownership and access to the email address associated with your account, and/or confirming other unique account details.</p>
            <p><strong>Your right to deletion.</strong>You may deactivate your EA Account or delete the personal information associated with your account by contacting us at support@joyboxgame.com. If you do so, you will no longer have access to the EA games or Services associated with your account. Note that JBS may retain information needed to resolve disputes, enforce our user agreements, protect our legal rights, and comply with technical and legal requirements and constraints related to the security, integrity and operation of our Services. Otherwise, we will retain your personal information for as long as reasonably necessary to provide you with, create, and improve our Services, and to comply with the law.</p>
            <p></p>
            <p></p>
            <p>5. Changes to Our Policy</p>
            <p>This policy will be updated from time to time and we will notify you of material changes.</p>
            <p>We may occasionally update this privacy policy. When we do, we will revise the "last updated" date at the bottom of the policy. If there are material changes to this policy, we will use reasonable efforts to notify you either by prominently posting a notice of such changes before they take effect or by directly sending you a notification.</p>
            <p></p>
            <p></p>
            <p>6. Governing Law</p>
            <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the USA laws.</p>
            <p></p>
            <p></p>
            <p>7. Contact Us</p>
            <p>
                If you have questions about this policy, please contact us in the following ways:
                <span class="p2">Email: <a class="email"
          href="mailto:support@joyboxgame.com?subject=【Guanwang】">support@joyboxgame.com.</a></span>
                <span class="p2">Address:4660 S. Eastern Avenue Suite 204C，Las Vegas, Nevada，89119，USA</span>
            </p>
            <p>Last Updated: March 20, 2024</p>
            <p></p>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
    name: "PricPage",
    components: {
        Header, Footer
    }
}
</script>

<style lang="scss" scoped>
.pric {
    width: 100%;
    padding: .4688rem 2.3438rem;
    background-color: #1a1a1a !important;

    .title {
        color: #fff;
        font-size: 30px;
        margin: 0 0 50px;
    }
    .tit{
        font-size: 20px;
        font-weight: bolder;
    }

    p {
        padding: 0 0 15px;
        font-size: 16px;
        color: #fff;
        line-height: 33px;
        .p2{
            display: block;
            padding:20px 0 0 40px;
        }
    }

    .email {
        color: #2987d4;
        text-decoration: none;
    }
}
</style>