<template>
  <div>
    <Header :message="'/terms.html'"></Header>
    <div class="pric">
      <h2 class="title">Terms of use</h2>
      <p>You agree to these terms when you use our games or any of our services.</p>
      <p>Welcome to Lovely Monster. This Agreement governs your access and use of products, content and services offered by Joybox Studio Limited(4660 S. Eastern Avenue Suite 204C，Las Vegas, Nevada，89119，USA) , such as game software and related updates, upgrades and features, and all online and mobile services, platforms, websites, and live events hosted by or associated with JoyBox Studio Limited (collectively "JBS Services").</p>
      <p>If you don’t agree, please don’t install or use our games or services.</p>
      <p><strong>BY USING JBS SERVICES, YOU AGREE TO THESE TERMS. IF YOU DO NOT AGREE, DO NOT INSTALL OR USE THE JBS SERVICES.</strong></p>
      <p></p>
      <p><strong>1. Account</strong></p>
      <p> You need an Account to play Lovely Monster. To create one, you must be over a certain age and you may cancel your Account at any time.JBS can suspend or terminate your account if you break this agreement.</p>
      <p>To create an Account, you must have a valid email address, and provide truthful and accurate information. You must be eligible to use the JBS Service for which you are registering and must be a resident of a country where use of JBS Services is permitted.</p>
      <p></p>
      <p></p>
      <p><strong>2. Availability of JBS Services and Updates</strong></p>
      <p>Our games and services may not always be available or operate on all devices. We also may make updates or changes to our games and services, which might impact your use or game progress.</p>
      <p>We do not guarantee that any JBS Service, Content or Entitlement will be available at all times, in all locations, or at any given time or that we will continue to offer a particular JBS Service, Content or Entitlements for any particular length of time. JBS does not guarantee that JBS Services can be accessed on all devices, by means of a specific Internet or connection provider, or in all geographic locations.</p>
      <p>From time to time, JBS may update, change or modify an JBS Service, Content or Entitlements, without notice to you. These updates and modifications may be required in order to continue to use JBS Services.</p>
      <p>JBS may need to update, or reset certain parameters to balance game play and usage of JBS Services. These updates or "resets" may cause you setbacks within the relevant game world and may affect characters, games, groups or other Entitlements under your control.</p>
      <p>JBS may also take actions on your Account and Entitlements without notice to you to protect you or JBS, such as preventing unauthorized access, resetting Account passwords, suspending JBS Account access, deleting data or removing Accounts from JBS Services. Your availability to JBS Services may also be affected in response to actual or suspected Rules of Conduct violations.</p>
      <p></p>
      <p></p>
      <p><strong>3. Rules of Conduct</strong></p>
      <p>We want you to have a good time playing our games. So we expect you, like all players, to respect JBS, our employees and representatives as well as your fellow players. This means, for example, obey the law, don’t cheat, don’t be offensive, don’t hack our software, don’t spam or bot, don’t lie to JBS or our players. Those are the highlights. Read the full list of what not to do in the Rules of Conduct.</p>
      <p>
        When you access or use an JBS Service, you agree that you will not:
        <span>Violate any law, rule or regulation.</span>
        <span>Interfere with or disrupt any JBS Service or any server or network used to support or provide an JBS Service, including any hacking or cracking into an JBS Service.</span>
        <span>Use any software or program that damages, interferes with or disrupts an JBS Service or another's computer or property, such as denial of service attacks, spamming, hacking, or uploading computer viruses, worms, Trojan horses, cancelbots, spyware, corrupted files and time bombs.</span>
        <span>Interfere with or disrupt another player's use of an JBS Service. This includes disrupting the normal flow of game play, chat or dialogue within an JBS Service by, for example, using vulgar or harassing language, being abusive, excessive shouting (all caps), spamming, flooding or hitting the return key repeatedly.</span>
        <span>Harass, threaten, bully, embarrass, spam or do anything else to another player or any JBS employee or representative that is unwanted, such as repeatedly sending unwanted messages or making personal attacks or statements about race, sexual orientation, religion, heritage, etc. Hate speech is not tolerated. We won't tolerate any unwanted conduct against any JBS employee and representative because of their affiliation in JBS Services or anywhere else.</span>
        <span>Organize or participate in any activity, group or guild that is inappropriate, abusive, harassing, profane, threatening, hateful, offensive, vulgar, obscene, sexually explicit, defamatory, infringing, invades another's privacy, or is otherwise reasonably objectionable.Publish, post, upload or distribute JBS or content that is illegal or that you don't have permission to freely distribute.</span>
        <span>Publish, post, upload or distribute JBS or content that is illegal or that you don't have permission to freely distribute.</span>
        <span>Publish, post, upload or distribute any content, such as a topic, name, screen name, avatar, persona, or other material or information, that JBS (acting reasonably and objectively) determines is inappropriate, abusive, hateful, harassing, profane, defamatory, threatening, obscene, sexually explicit, infringing, privacy-invasive, vulgar, offensive, indecent or unlawful.</span>
        <span>Post a message for any purpose other than personal communication. Prohibited messages include advertising, spam, chain letters, pyramid schemes and other types of solicitation or commercial activities.</span>
        <span>Impersonate another person or falsely imply that you are an JBS employee or representative.</span>
        <span>Improperly use in-game support or complaint buttons or make false reports to JBS staff.</span>
        <span>Attempt to obtain, or phish for, a password, account information, or other private information from anyone else on JBS Services.</span>
        <span>Make use of any payment methods or refund systems to access, purchase or refund JBS Services for fraudulent purposes, or without permission of the authorized owner, or otherwise concerning a criminal offence or other unlawful activity.</span>
        <span>Use any robot, spider or other automated device or process to access JBS Services for any purpose such as scraping data, abuse JBS Services, account creation, or copying material.Modify any file or any other part of the JBS Service that JBS does not specifically authorize you to modify.</span>
        <span>Modify any file or any other part of the JBS Service that JBS does not specifically authorize you to modify.</span>
        <span>Use or distribute unauthorized software programs or tools (such as "auto", "macro", hack or cheat software), or use exploits, bugs or problems in an JBS Service to gain unfair advantage.</span>
        <span>Engage or assist in cheating or other anticompetitive behavior (such as boosting, collusion, and match or matchmaking manipulation).</span>
        <span>Use or distribute counterfeit software or JBS Content.</span>
        <span>Attempt to use an JBS Service on or through any service that is not controlled or authorized by JBS, or otherwise intentionally obfuscate your network connection or location or other metadata to gain access to an EA Service, make purchases, or otherwise access an JBS Service.</span>
        <span>Sell, buy, share, trade or otherwise transfer or offer to transfer your JBS Account, any personal access to JBS Services, or any JBS Content associated with your JBS Account, including other Entitlements, either within an JBS Service or on a third-party website, or in connection with any out-of-game transaction, unless expressly authorized by JBS.</span>
        <span>Use an JBS Service in a country in which JBS is prohibited from offering such services under applicable export control laws.</span>
        <span>If an JBS Service requires you to create a "username" or a "persona" to represent yourself in-game and online, you should not use your real name and may not use a username or persona that is used by someone else or that EA determines is vulgar or offensive or violates someone else's rights.</span>
        <span>Engage in any other activity that significantly disturbs the peaceful, fair and respectful gaming environment of a JBS Service.</span>
        <span>Use information about users publicly available in any JBS Service (e.g. on a leaderboard) for any purpose unrelated to the Service, including to attempt to identify such users in the real world.</span>
        <span>Promote, encourage or take part in any prohibited activity described above.</span>
      </p>
      <p></p>
      <p>To enforce these rules, we may monitor your activity and remove Account. If you don’t follow these rules, we may warn you, suspend you, ban you permanently or place other restrictions on your Account, games, or related services.</p>
      <p>If you or someone using your Account violates these rules and fails to remedy this violation after a warning,JBS may take action against you, including revoking access to certain or all JBS Services, Content or Entitlements, or terminating your Account . In case of severe violations,JBS may take these actions without issuing a prior warning. Some examples of severe violations include, but are not limited to: promoting, encouraging or engaging in hacking, selling JBS accounts or entitlements (including virtual currencies and items) without JBS’s permission, extreme harassment, or threatening illegal activities. When practical, JBS will notify you of the action it will take in response to violations of these rules or breach of this Agreement.</p>
      <p>Specific JBS Services may post additional rules that apply to your conduct on those services.</p>
      <p>If you encounter another user who is violating any of these rules, please report this activity to JBS using the "Help" or "Report Abuse" functions in the relevant JBS Service, if available, or contact Customer Support at support@joyboxgame.com.</p>
      <p>JBS may, in its discretion, monitor or record online activity or Content on JBS Services and may remove any Content from any JBS Service at its discretion. Remember that your communications in JBS Service are public and will be seen by others.</p>
      <p>Your use of JBS Services is subject to JBS's Privacy and Cookie Policy , which is incorporated by reference into this Agreement.</p>
      <p></p>
      <p></p>
      <p><strong>4. Games</strong></p>
      <p>The Games may download and install updates, upgrades and additional features.</p>
      <p>You agree that JBS has no obligation to support previous version(s) of the app upon the availability of an update, upgrade and/or implementation of additional features. JBS may provide you with the option to download, install and use an alpha or beta version of the app under these same terms.</p>
      <p></p>
      <p></p>
      <p><strong>5. Termination and Other Sanctions</strong></p>
      <p>If you break this agreement or the law,JBS may suspend or terminate your use of our games and services, without refunds. If we decide to shut down a game or a service, we will tell you at least 30 days in advance.</p>
      <p>This Agreement is effective until terminated by you or JBS. JBS may terminate your access and use of any JBS Services or your Account if JBS determines that you have violated this Agreement or that there has been otherwise unlawful, improper or fraudulent use of JBS Services on your Account. When practical, JBS will notify you of the termination. You may lose your username and persona as a result of an Account termination. If you have more than one Account, depending on the type of violation or misuse, JBS may terminate all of your Accounts and all related Entitlements. If your Account is terminated, you will not have access to your Account or Entitlements and may be barred from accessing or using any JBS Service again. Upon termination, your license under this Agreement also shall terminate.</p>
      <p>Instead of termination and prior to any termination, JBS may issue you a warning, suspend or alter your access to a particular JBS Service or your Account, remove or revoke Entitlements at an Account or device level, remove or delete any Content which is in violation with this Agreement, or ban your device or machine from accessing specific JBS Services. If JBS takes any action described in this Section, you will not be entitled to a refund (subject to any statutory refund rights) and no Entitlements will be credited to you or converted to cash or other forms of reimbursement.</p>
      <p>JBS may terminate any JBS Service at any time by giving at least thirty days' notice either via email (if available), within the affected JBS Service. After online service termination, no software updates will be applied to our games and we can't guarantee our games will continue to function on newer or updated operating systems or be available for download via application distribution services such as the iOS App Store and the Google Play Store. Any games available via such application distribution services after online service termination may be removed without further notice to you.</p>
      <p></p>
      <p></p>
      <p><strong>6. Use of Data</strong></p>
      <p>JBS collects various information when you play our games (even offline) to operate our business, improve our products and services, enforce our rules and communicate with you. We encourage you to read Cookie Policy .</p>
      <p>When you play the games, JBS may collect and store data from your computer or device, including information about your computer or device, hardware, installed software, and operating system (such as IP Address and device ID), information about your JBS Service usage, gameplay and usage statistics, system interactions and peripheral hardware. If you play an JBS Service offline, this data will be stored on your device and transmitted to JBS when your device connects to the Internet. JBS uses this information to operate its business, improve its products and services, provide services to and communicate with you (including for marketing purposes), provide software updates, dynamically serve content and software support, enforce this Agreement, and trouble-shoot bugs or otherwise enhance your experience. If you participate in online services, JBS also may collect, use, store, transmit and publicly display statistical data regarding game play (including scores, rankings and accomplishments), or identify content that is created and shared by you with other players.</p>
      <p>You can manage certain data collection preferences in the Settings tab of the game client.</p>
      <p></p>
      <p></p>
      <p>7.Refund Policy. We will not grant you any refunds, unless otherwise required by law.</p>
      <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Nevada, United State.</p>
      <p></p>
      <p></p>
      <p>8.Intellectual property rights</p>
      <p>In accordance with the Digital Millennium Copyright Act (DMCA) and other applicable laws, the Company may, in appropriate circumstances and at its sole discretion, terminate your access to this Site if it considers you to be a repeat infringer. The Company may also, at its sole discretion, limit your access to the Site and/or terminate your membership if you infringe on the intellectual property rights of others, regardless of whether there is any repeat infringement.</p>
      <p>If you become aware of any content that may infringe the copyright of a third party or that you believe to be in violation of these Terms of Use, please contact us. Additionally, if you believe that any content on the Site violates your intellectual property rights, please email us at support@joyboxgame.com.</p>
      <p></p>
      <p></p>
      <p>9.Changes</p>
      <p>We can update and change these Terms from time to time and the most current version of these Terms will be posted onto the Website and relevant apps and you may be invited to review and accept the revised Terms in order to continue using the Services. You can print and save a copy of these Terms for your future reference.</p>
      <p></p>
      <p></p>
      <p>10.Governing Law</p>
      <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Nevada, United State.</p>
      <p></p>
      <p><strong>Last Updated: March 7, 2024</strong></p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: "TermsPage",
  components: {
    Header, Footer
  }
}
</script>

<style lang="scss" scoped>
.pric {
  width: 100%;
  padding: .4688rem 2.3438rem;
  background-color: #1a1a1a !important;

  .title {
    color: #fff;
    font-size: 30px;
  }

  p {
    padding: 0 0 15px;
    font-size: 16px;
    color: #fff;
    line-height: 33px;
    span{
      display: block;
      padding:20px 0 0 40px;
    }
  }

  .email {
    color: #2987d4;
    text-decoration: none;
  }
}
</style>